.formStyle{
  width: 88.9vw;
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.12);
    padding: 5rem;
    border: 1px solid #FFF;
    background-color: white;
    position: absolute;
    margin-top: -12rem;
    margin-left: -13rem;
    /* height: 97.9vh; */
    overflow: hidden;
    position: relative;
  }

  .sub-container{
    background-color: #E7F2FF;
    padding:18rem  18rem 5rem 18rem;
    
  }
    
  .footerStyle {
    position: relative;
    display: flex;
    /* //width: 1440px; */
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: flex-start;
    background: #02054E;
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.12);
}
.display{
  display: block;
}

.hide{
  display: none;
}
.pannel{
  height: 100%;
  /* overflow: auto; */
/* position: absolute; */
/* overflow: hidden; */

}

.footerFont {
  font-family: Graphik;
font-size: 18px;
font-weight: 500;
line-height: 28px;
letter-spacing: 0em;
text-align: left;

}
.truncated-url:hover .url-text::after {
  content: attr(data-url);
  position: absolute;
  top: -1.5em;
  left: 0;
  padding: 0.5em;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  white-space: normal;
  z-index: 9999;
  max-width: 60%;
}
