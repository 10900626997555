.url-container {

    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .truncated-url:hover .url-text::after {
    content: attr(data-url);
    position: absolute;
    top: -1.5em;
    left: 0;
    padding: 0.5em;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    white-space: normal;
    z-index: 9999;
  }
  
  .analyzed-url{
    /* position: absolute; */
    width: 300px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .analyzed-url-heading{
    /* position: absolute; */
    width: 54% !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .analyzed-url-label{
    position: absolute;
    width: 80% !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .analyzed-url-label2{
    width: 80% !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .reportNames{
    color: var(--grayscale-black, #1E1E1E);
    font-family: Graphik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
  }
  .teamDescription {
    font-family: Graphik;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
  
  }
  
  .teamsICon {
    padding-top: 7px;
    padding-bottom: 12px;
  }
  .deleteIcon {
    color: #A100FF;
font-family: Font Awesome 5 Pro;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
  }

.editImage {
  margin-right: 12px;
}

.progress-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  /* padding: 20px; */
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
  /* width: 80%;
  max-width: 600px; */
}

.progress-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 600px;
}

h2 {
  color: #333;
  margin-bottom: 20px;
}

.report-list {
  list-style: none;
  padding: 0;
 
margin: 0;
}

.report-item {
background: #f9f9f9;
border: 1px solid #ddd;
padding: 10px;
margin-bottom: 10px;
border-radius: 4px;
display: flex;
justify-content: space-between;
align-items: center;
}

.report-name {
font-size: 1rem;
font-weight: bold;
color: #333;
}

.report-status {
font-size: 0.9rem;
padding: 3px 6px;
border-radius: 4px;
}

.report-status.completed {
background-color: #28a745;
color: white;
}

.report-status.in-progress {
background-color: #ffc107;
color: white;
}

.close-modal {
background-color: #007bff;
color: white;
border: none;
padding: 10px 20px;
border-radius: 4px;
font-size: 1rem;
cursor: pointer;
margin-top: 20px;
transition: background-color 0.3s;
}

.close-modal:hover {
background-color: #0056b3;
}