.xl-input {
  width: 100%;
}

body {
  font-family: "Graphik LC Web !important";
  font-weight: 400 !important;
  font-style: normal;
  font-stretch: normal;
}

//GRAPHIK

@font-face {
  font-family: "Graphik Web !important";
  src: url("../../fonts/graphik/Graphik-Black-Web.eot");
  src: url("../../fonts/graphik/Graphik-Black-Web.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/graphik/Graphik-Black-Web.woff2") format("woff2"),
    url("../../fonts/graphik/Graphik-Black-Web.woff") format("woff");
  font-weight: 800 !important;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Black-Web {
  font-family: "Graphik Web !important";
  font-weight: 800 !important;
  font-style: normal !important;
  font-stretch: normal !important;
}

@font-face {
  font-family: "Graphik Web !important";
  src: url("../../fonts/graphik/Graphik-Bold-Web.eot");
  src: url("../../fonts/graphik/Graphik-Bold-Web.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/graphik/Graphik-Bold-Web.woff2") format("woff2"),
    url("../../fonts/graphik/Graphik-Bold-Web.woff") format("woff");
  font-weight: 700 !important;
  font-style: normal !important;
  font-stretch: normal !important;
}

.Graphik-Bold-Web {
  font-family: "Graphik Web !important";
  font-weight: 700 !important;
  font-style: normal !important;
  font-stretch: normal !important;
}

@font-face {
  font-family: "Graphik Web !important";
  src: url("../../fonts/graphik/Graphik-Medium-Web.eot");
  src: url("../../fonts/graphik/Graphik-Medium-Web.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/graphik/Graphik-Medium-Web.woff2") format("woff2"),
    url("../../fonts/graphik/Graphik-Medium-Web.woff") format("woff");
  font-weight: 500 !important;
  font-style: normal !important;
  font-stretch: normal !important;
}

.Graphik-Medium-Web {
  font-family: "Graphik Web" !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-stretch: normal !important;
}

@font-face {
  font-family: "Graphik LC Web" !important;
  src: url("../../fonts/graphik/Graphik-Regular-Web.eot");
  src: url("../../fonts/graphik/Graphik-Regular-Web.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/graphik/Graphik-Regular-Web.woff2") format("woff2"),
    url("../../fonts/graphik/Graphik-Regular-Web.woff") format("woff");
  font-weight: 400 !important;
  font-style: normal !important;
  font-stretch: normal !important;
}

.Graphik-Regular-Web {
  font-family: "Graphik LC Web" !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-stretch: normal !important;
}

.bold {
  font-weight: bold !important;
}

a.normal-a {
  color: inherit;
}

.full-height {
  height: 100%;
}

.center-vertically {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scroll-overflow {
  overflow-y: scroll;
  height: 1500px;
}

//Red, green, warning cubes in the reports
.cube {
  width: 16px;
  height: 16px;
}

.cube.success {
  background-color: #d2edb6;
}

.cube.warning {
  background-color: #ede4b6;
}

.cube.danger {
  background-color: #edc3b6;
}

.tab-pane.active {
  background-color: white;
  background-color: white;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-top: transparent;
  border-color: #dee2e6 #dee2e6 #dee2e6;
}

.border-row {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-right: transparent;
  border-left: transparent;
  border-color: #dee2e6 #dee2e6 #dee2e6;
}

.score-round {
  width: 100px;
  height: 100px;
  text-align: center;
  vertical-align: middle;
  border-radius: 90px;
  background-color: #f0f0f0;
  margin: auto;
  line-height: 100px;
  font-size: 35px;
  font-weight: 900;
}

.score-label {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 14px;
  left: 50%;
  transform: translateX(-50%);
  display: table;
  margin-top: 10px;
}

hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.icon-score {
  width: 18px;
  max-width: 18px;
}

.no-top-border {
  border-top: none;
}

.no-bottom-border {
  border-bottom: none;
}

.text-title {
  font-weight: 900;
}
.predictiveFull{
  // height: 3590px !important;
  padding-bottom: 120px;
}
.dark-background {
  background-color: #f0f0f0;
  border: 7px solid white;
  border-top: none;
  border-bottom: none;
}

.card-grey {
  background-color: #f0f0f0;
  color: #63799b !important;
}

.text-dark-blue {
  color: #63799b !important;
}

.category-text {
  font-size: 20px;
  padding-top: 0.3rem;
}

.top-text {
  font-size: 24px;
}

.top-text-label {
  font-size: 20px;
  margin-bottom: 2%;
}

.max-height {
  max-height: 300px;
  text-align: center;
  // margin: auto;
}

.max-height-view {
  max-height: 220px !important;
}

.serious-link {
  color: inherit !important;
  text-decoration: underline;
}

.disabled-grayscale {
  filter: opacity(0.8) grayscale(0.7);
}

.small-score-label {
  font-size: 10px;
  font-weight: 700 !important;
  margin-top: 3px;
}

.success-label {
  background-color: #d9f5bd;
}

.fail-label {
  background-color: #efdcd6;
}

.warning-label {
  background-color: #fdf1cb;
}

input {
  border-radius: 25px !important;
}

.tabs-design {
  // width: 23%;
  text-align: center;
  // margin-left: 1%;
  // margin-right: 1%;
  font-weight: bold !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tabs-design a {
  // border-top-left-radius: 14px !important;
  // border-top-right-radius: 14px !important;
  // background-color: #dedee2;
  color: #1E1E1E !important;
}

.tabs-design a.active {
  border: none;
  border-bottom: 9px solid #B230FF !important;
  color: #1E1E1E !important;
  // border-top-left-radius: 14px;
  // border-top-right-radius: 14px;
}

.nav-design {
  // width: 23%;
  text-align: center;
  // margin-left: 1%;
  // margin-right: 1%;
  font-weight: bold !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.nav-design a {
  // border-top-left-radius: 14px !important;
  // border-top-right-radius: 14px !important;
  // background-color: #dedee2;
  color:  #1E1E1E !important;
  height: 8.3vh;
}

.nav-design a.active {
  background-color: none !important;
  border: none;
  border-bottom: 4px solid #B230FF !important;
  color: #1E1E1E !important;
  // border-top-left-radius: 14px;
  // border-top-right-radius: 14px;
}

.reportTable{
  box-shadow:  1px 10px -1px rgba(0, 0, 0, 0.5);
}
.input-group>input {
  border-radius: 25px 0px 0px 25px !important;
}

.input-group-text {
  border-radius: 0px 25px 25px 0px !important;
}

.card-img {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  opacity: 0.6;
}

.input-blue-bg {
  background-color: #f3f5f7 !important;
}

.search-table {
  width: 200px !important;
}

.small-error {
  color: red;
  margin-bottom: 0.75rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 27px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #7500c0;
}

input:focus+.slider {
  box-shadow: 0 0 1px #7500c0;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.wrapper {
  height: 100% !important;
}

.clickable-rows .rdt_TableRow {
  cursor: pointer;
}

.prev {
  font-size: 2rem;
  margin-bottom: 1rem;
  -webkit-text-stroke: 5px white;
}

.badge {
  font-size: 93%;
}

.caret-s {
  top: 16px;
  position: absolute;
  right: 10px;
  transition: all 0.15s ease 0s;
}

.caret-s.up {
  transform: rotate(180deg);
}

.sidebar .nav li.collapse-open>a {
  opacity: 1;
}

.logo {
  text-align: center;
  width: 100%;
}

.logo a {
  margin: auto;
  width: 50%;
}

.admin-hr {
  text-align: center;
  color: white;
}

.admin-hr:before {
  content: "";
  border: 1px solid rgba(255, 255, 255, 0.425);
  position: absolute;
  width: 50%;
  top: 220px;
  left: -45px;
}

.admin-hr:after {
  content: "";
  border: 1px solid rgba(255, 255, 255, 0.425);
  width: 50%;
  position: absolute;
  top: 220px;
  right: -45px;
}

.col-0 {
  flex: 0 0 0% !important;
  transition: all 500ms ease;
  opacity: 0;
  width: 0 !important;
}

.img-thumb {
  position: relative;
  overflow: hidden;
  padding: 10px;
}

.img-thumb img {
  object-fit: cover;
  width: 200px;
  height: 200px;
  background: black;
}

.thumbcontainer {
  display: flex;
  flex-wrap: wrap;
}

.square:before {
  content: "";
  display: block;
  padding-top: 100%;
  /* initial ratio of 1:1*/
}

.dropzone h3 {
  text-align: center;
}

.dz-active h3 {
  color: #7500c0;
}

.more-sm {
  font-size: 2rem;
  color: #7500c0;
}

.more-big {
  font-size: 4rem;
  color: #7500c0;
}

.hide {
  opacity: 0;
  transition: all 1s ease;
}

.fade-out {
  -webkit-animation: fade-out 400ms ease-out both;
  animation: fade-out 400ms ease-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-8-5 17:16:35
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade-in {
  // height: auto !important;
  // -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  // animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.teamCard{
  height: 172px !important;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-8-5 17:17:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.thumbcontainer .progress {
  position: absolute;
  top: 25%;
  left: 10%;
  width: 80%;
  z-index: 10;
}

.img-thumb.inprogress img {
  filter: brightness(50%);
}

.img-thumb.loaded img {
  filter: brightness(100%);
}

.img-thumb video {
  object-fit: cover;
  width: 200px;
  height: 200px;
}

.form-up {
  z-index: 13;
}

.form-create {
  display: none;
}

.form-active {
  display: block !important;
}

.search-input-icon {
  color: #7500c0;
  font-size: 1.8rem;
}

.thumbcontainer-url img {
  margin-top: 2rem;
}

.spinner-rotate {
  -webkit-animation: rotate-center 2s linear infinite both;
  animation: rotate-center 2s linear infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-8-11 15:46:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.azure-bg {
  background-color: #f3f5f7;
  width: 100vw;
  height: 100vh;
}

.error-bg {
  background-color: #07242c;
  width: 100vw;
  height: 100vh;
}

.spinner-loggin {
  font-size: 5rem;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.error-h {
  text-align: center;

  button {
    border: 1px solid white;
    color: white;
    background-color: #fff0;
  }

  button:hover,
  button:focus,
  button:active {
    background-color: rgba(255, 255, 255, 0.226) !important;
  }

  h2 {
    font-size: 8rem;
    color: #7500c0;
  }

  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.admin-sep {
  text-align: center;
  width: 100%;
  color: white;
}

.admin-sep::after {
  content: "\2002\2002\2014\2014\2014";
}

.admin-sep::before {
  content: "\2014\2014\2014\2002\2002";
}

.cursorp {
  cursor: pointer;
}

.task-pending img,
.task-error img {
  filter: brightness(30%);
}

img {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.task-spinner {
  position: absolute;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  color: #be82ff;
  font-size: 4rem;
}

.task-error-icon-container {
  position: absolute;
  z-index: 10;
  top: 50%;
  /* position the top  edge of the element at the middle of the parent */
  left: 50%;
  /* position the left edge of the element at the middle of the parent */
  color: #ff5959;
  font-weight: 700;
  transform: translate(-50%, -50%);
  text-align: center;
}

.task-error-icon {
  font-size: 4rem;
}

.ul-hide {
  padding: 0;
  list-style-type: none;
}

.full-page {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  background-color: #337ab72e;
}

.full-page .powered-by-text {
  // margin: 5rem 0 0 0;
  color: white !important;
  font-size: 15px;
  display: block;
  text-align-last: center;
}

.full-page-background {
  z-index: 1;
  // height: 97%;
  width: 97%;
  display: block;
  background-size: cover;
  background-position: center;
  background-image: url("../../../../public/img/bg_image.png");
  border-radius: 20px;
}

.navbar-absolute {
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 1029;
}

.navbar-login .navbar.navbar-absolute {
  padding: 0 2rem 0 0;
  margin: -2rem 0 0 0;
  position: absolute;
  width: 100%;
  z-index: 1029;
}

.navbar-transparent {
  background-color: transparent !important;
  box-shadow: none;
  border-bottom: none !important;
  margin-top: 20px;
}

.navbar-login .navbar-transparent {
  background-color: transparent !important;
  box-shadow: none !important;
  border-bottom: none !important;
}

.navbar.navbar-transparent.home .nav-item {
  .nav-link {
    color: #3a3937 !important;
  }

  &.active .nav-link {
    color: #a100ff !important;
    ;
  }
}

.nav-item {
  cursor: pointer;
}

.navbar-text-dark .nav-link {
  color: #66615b !important;
}

.navbar.navbar-transparent .nav-item .nav-link {
  color: #66615b !important;
}

.navbar-login .navbar .nav-item .nav-link p {
  color: #ffffff !important;
  text-decoration: underline !important;
  font-size: smaller !important;
}

.full-page .container {
  z-index: 1200;
  position: relative;
  margin-right: 0rem;
  width: 40%;
}

.card-login {
  max-width: 75%;
  margin-top: -0.13rem;
  margin-left: 3rem;
  background: rgba(0, 0, 0, 0.5) !important;
  border-radius: 25px !important;
}

.big-card-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 2.2rem;
  font-weight: 500;
}

.center-btn {
  margin: 1rem auto 0 !important;
  display: block !important;
}

.img-acn {
  max-width: 150px;
}

.card-login label,
.card-register label {
  color: #FFFFFF;
  font-size: 14px;
}

.text-register {
  font-size: 0.8rem;
  text-align: center;
  color: #ffffff;
}

.link {
  color: #7500c0;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.title-info {
  font-weight: 600;
}

.rdt_TableCol_Sortable {
  font-size: 1rem;
  font-weight: 500;
  color: #7500c0;
}

.block-info {
  line-height: 1.7rem;
}

.project-title {
  font-size: 1.4rem;
  font-weight: 600;
}

.date-card {
  color: var(--grayscale-black, #1E1E1E);
  font-family: Graphik;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
}

.icon-card {
  font-size: 1.1rem;
  color:  #A100FF;
  ;
}

.card-img-alt {
  width: 100%;
  height: 10vw;
  object-fit: cover;
}

.method-icon {
  font-size: 1.7rem;
  width: 1em !important;
  color: #A100FF;
  height: 1em !important;

  // background-color: rgba(224, 222, 222, 0.877);
  // padding: 0.2rem;
  // border-radius: 5px;
  &.url {
    color: $info-color;
  }

  &.chrome {
    color: $orange-color;
  }

  &.api {
    color: $purple-color;
  }

  &.adobe {
    color: #570303;
  }

  &.upload {
    color: #b8ab00;
  }

  &.dom {
    color: $success-color;
  }

  &.image {
    color: #66615b;
  }

  &.video {
    color: $danger-color;
  }
}

.container-icon-task {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bordered-col {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.title-info-task {
  font-weight: 600;
  font-size: 0.8rem;
}

.task-title {
  font-family: Graphik;
  font-size: 16px;
  // font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  
}

.card-img-video {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10vw;
  object-fit: cover;
}

#page-title {
  font-size: 2.1vw;
  color: #66615b;
}

.page-title {
  font-size: 24px;
  color: #66615b;
}

h5 .badge {
  font-size: 0.82rem !important;
}

.wrapper-quotas {
  padding: 0.5rem;
}

.chevron-height {
  // height: 100%;
  padding-top: 8rem;
  position: absolute;
}

.chevron-height-right {
  // height: 100%;
  padding-top: 8rem;
  position: absolute;
  right: 0;
}

.chevron-right {
  position: sticky;
  font-size: 30px;
  z-index: 100;
  top: 50%;
  transform: translateY(-50%);
  overflow: auto;
  margin: auto;
  text-shadow: 1px 4px 4px #7171717d;
  cursor: pointer;
}

.chevron-left {
  position: sticky;
  font-size: 30px;
  z-index: 100;
  top: 50%;
  transform: translateY(-50%);
  overflow: auto;
  margin: auto;
  text-shadow: 1px 4px 4px #7171717d;
  cursor: pointer;
}

.chevron-spacing {
  width: 25px;
  display: inline-table;
}

.addingScroll{
  overflow-y: auto !important;
  display: flex;
  width: 100%;
  scroll-behavior: smooth;
}
.container-scroll {
  overflow: hidden;
  // overflow-y: auto;
  display: flex;
  width: 100%;
  scroll-behavior: smooth;
}

.gradient-right {
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 4%;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 40%,
      #f3f5f7 80%);
}

.gradient-left {
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 4%;
  background: linear-gradient(to right, #f3f5f7 0%, rgba(255, 255, 255, 0) 40%);
}

.btn-feedback {
  z-index: 1;
  position: fixed;
  left: 99.2%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  z-index: 3000;
}

.feedback-check-label {
  height: auto;
  background-color: #dddad5;
  display: inline-block;
  padding: 1rem;
  border-radius: 7px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.feedback-form input[type="radio"] {
  opacity: 0;
}

.feedback-form input[type="radio"]:checked+.feedback-check-label {
  background-color: #7500c0;
  color: white;
}

.feedback-icon {
  font-size: 1.6rem;
  margin-bottom: 0.3rem;
}

.dz-raw {
  margin-top: 1.4rem;
  padding: 1rem;
  border: dashed orange 2px;
}

.img-sm {
  width: 5rem;
}

.form-create-check {
  font-size: 1.1rem;

  label {
    color: #a100ff !important;
  }

  .disabled-txt {
    color: #d1d1d1;
  }

  .form-check-sign.disabled::before {
    background-color: #dbdbdb;
  }

  input[type="checkbox"]:checked+.form-check-sign.disabled:before {
    background-color: #a100ff4b;
  }

  input[type="checkbox"]:checked+.form-check-sign:before {
    background-color: #a100ff !important;
  }
}

.warning {
  color: #7500c0;
}

.success {
  color: #00a838;
}

.video-result {
  width: 100% !important;
}

.white-bg {
  background-color: white !important;
}

.score-memo {
  font-size: 1.2rem;
  text-align: center;
}

.bg-yellow {
  background-color: rgb(221, 217, 0) !important;
}

.bg-purple {
  background-color: #972ad6;
}

.name-html {
  position: absolute;
  z-index: 12;
  top: 4%;
  left: 15%;
  width: 70%;
  text-align: center;
  font-size: 1rem;
  color: #cc75ff;
}

.autocomplete {
  background-color: #efefef !important;
  border-radius: 6px !important;
  padding-top: 5px !important;
  list-style-type: none;
  position: absolute !important;
  width: 94% !important; 
}

a.disabled {
  pointer-events: none;
}

.react-select__control--menu-is-open,
.react-select__control--is-focused {
  border-color: #972ad6 !important;
  box-shadow: none !important;
}

.react-select__option:hover,
.react-select__option--is-focused {
  background-color: #972ad621 !important;
}

.sidebar-wrapper::-webkit-scrollbar {
  display: none;
}

.rendering-container {
  // height: 250px;
  position: relative;
  top: 0;
  left: 0;
  flex-wrap: wrap;
}

.rendering-content-container {
  position: absolute;

  overflow: auto;

  &.mobile {
    left: 6.5%;
    top: 11%;
    width: 87%;
    height: 78%;
  }

  &.tablet {
    left: 4.2%;
    top: 5.2%;
    width: 92%;
    height: 88.6%;
  }

  &.desktop {
    left: 11.3%;
    top: 7.2%;
    width: 77.6%;
    height: 84.4%;
  }
}

.rendering-content-container::-webkit-scrollbar {
  display: none;
}

.rendering-device {
  position: relative;
  top: 0;
  left: 0;
}

.modal-rendering {
  &>.modal-content {
    background-color: transparent;
    border: none;
    height: 80%;
  }
}

.progress-text {
  position: absolute;
  text-align: center;
  line-height: 16px;
  overflow: hidden;
  color: #000;
  right: 0;
  left: 0;
  top: 0;
}

.text-menu p {
  font-size: 1rem !important;
  color: white;
}

.text-menu .nav-item.active .nav-link p {
  color: white !important;
  text-decoration: underline;
}

.logo-card {
  position: absolute;
  top: -85px;
  right: 170%;
  width: 250px !important;
  height: 150px;
}

.dropbox_text {
  height: 2.5rem;
  display: inline-flex;
  margin-left: 1rem;
}

.dropbox_text svg {
  width: 1.6rem !important;
  padding-top: 0.5rem;
}

.dropbox_text span {
  padding-top: 0.5rem;
 // font-weight: 500;
  font-size: large;
}

.logo-landing {
  margin-left: 40%;
}

.text-content {
  font-size: 1.2rem !important;
  font-weight: bold !important;
  color: white;
}

.text-dark-blue .loading-div {
  height: 300px;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.text-dark-blue .loading-div span {
  font-weight: 700;
  padding-left: 0.5rem;
}

.quiet-a {
  color: #000 !important;
}

.project-name {
  font-size: 0.9rem;
}

.badge-task {
  font-size: 0.5rem !important;
  background-color: white !important;
  border: solid 1px #972ad6 !important;
  color: #972ad6 !important;

  &.active {
    background-color: #972ad6 !important;
    border: solid 1px #972ad6 !important;
    color: white !important;
  }

  line-height: 1.2;
}

.col-19 {
  padding-right: 20px;
  padding-left: 15px;
  flex: 0 0 25%;
  max-width: 25%; 

  // padding-right: 15px;
  // padding-left: 15px;
}

.info-task {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 15;
  width: 20%;
  padding-left: 0;
  padding-top: 0;
  padding-right: 0;
  color: white;
  font-size: 0.75rem;
  height: 30%;
  clip-path: polygon(0 100%,
      100% 0,
      100% 0,
      100% 50%,
      100% 100%,
      100% 100%,
      10% 100%,
      0% 100%,
      0% 50%);
  background-color: #972ad6;
  cursor: pointer;

  &>.row {
    display: none;
  }

  &>svg {
    position: absolute;
    top: 44%;
    left: 65%;
    color: white;
    font-size: 1vw;
  }

  &.info-out {
    cursor: pointer;
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
    -webkit-animation: info-out 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: info-out 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;

    &>.row {
      display: none;
    }
  }

  &.info-in {
    cursor: default;
    padding-left: 2vw;
    padding-top: 1.7vw;
    padding-right: 1vw;
    display: block !important;
    -webkit-animation: info-in 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: info-in 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;

    &>svg {
      display: none;
    }

    &>.row {
      display: flex;
    }
  }
}

@-webkit-keyframes info-out {
  100% {
    width: 20%;
    height: 30%;
    color: #972ad6;
    clip-path: polygon(0 100%,
        100% 0,
        100% 0,
        100% 50%,
        100% 100%,
        100% 100%,
        10% 100%,
        0% 100%,
        0% 50%);
  }

  0% {
    width: 100%;
    height: 100%;
  }
}

@keyframes info-out {
  100% {
    width: 20%;
    height: 30%;
    color: #972ad6;
    clip-path: polygon(0 100%,
        100% 0,
        100% 0,
        100% 50%,
        100% 100%,
        100% 100%,
        10% 100%,
        0% 100%,
        0% 50%);
  }

  0% {
    width: 100%;
    height: 100%;
    color: white;
  }
}

@-webkit-keyframes info-in {
  0% {
    width: 20%;
    height: 30%;
  }

  100% {
    width: 100%;
    height: 100%;
    clip-path: polygon(0 33%,
        22% 0,
        100% 0,
        100% 10%,
        100% 100%,
        100% 100%,
        10% 100%,
        0% 100%,
        0% 10%);
  }
}

@keyframes info-in {
  0% {
    widows: 20%;
    height: 30%;
    color: #972ad6;
  }

  100% {
    width: 100%;
    height: 100%;
    color: white;
    clip-path: polygon(0 33%,
        22% 0,
        100% 0,
        100% 10%,
        100% 100%,
        100% 100%,
        10% 100%,
        0% 100%,
        0% 10%);
  }
}

.not-specified {
  color: #acacac;
}

.sidebar::after {
  background-image: linear-gradient(to bottom, #07242cc7, #07242cc7),
    url("../../../../public/img/bg_img.png") !important;
  background-position: center !important;
}

.cardimg-project-container>span {
  width: 100%;
}


.info-icon {
  margin-left: 0.05rem;
  margin-right: 0.3rem;
  color: #3a3937;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.sticky-menu {
  align-self: flex-start;
  top: 50px;
  position: -webkit-sticky;
  position: sticky;
}


.category-text-2 {
  font-size: 32px;
  text-align: center;
  width: 100%;
  background-color: #f0f0f0;

}

.category-text-description {
  font-size: 14px;
  font-style: italic;
}

.category-text-image img {
  max-width: 100%;
}

.text-decoration-underline {
  text-decoration: underline;
  font-size: 1rem;
}

.scoring-text {
  font-size: 11px;
}

.accessibility-iframe {
  width: 100%;
  height: 70rem;
}

.score-color-palet {
  display: flex;
  margin: 0.5rem 0;
}

.score-color {
  text-align: center;
  width: 5rem;
  height: 2.5rem;
  padding-top: 0.75rem;
  font-size: 11px !important;
  font-weight: bold;
}

.dom-score-color {
  text-align: center;
  width: 5rem;
  padding-top: 0.25rem;
  font-size: 11px !important;
  font-weight: 700;
}

.score-color-one {
  background-color: #beeb91;
}

.score-color-two {
  background-color: #d9f5bd;
}

.score-color-three {
  background-color: #fdf1cb;
}

.score-color-four {
  background-color: #efdcd6;
}

.score-color-five {
  background-color: #eabcae;
}

.score_card_color {
  display: flex;
}

.score-color-scale {
  float: right;
  width: 2rem;
  height: 1rem;
  margin-top: 0.13rem;
}

.sentiment-card {
  width: max-content;
}

.col-sentiment-card {
  max-width: 30% !important;
}

.memorability-score-row {
  margin: 1rem !important;
}

.memorability-score-col {
  padding-top: 0.5rem;
}

.emotion-color-palet {
  display: inline-flex;
}

.emotion-text {
  padding-right: 2rem;
}

.emotion-color {
  text-align: center;
  width: 14rem;
  height: 2.5rem;
  padding: 0.25rem 0.25rem 0.15rem;
  font-size: 11px !important;
  font-weight: 700;
}

.score-tag {
  padding-left: 10px;
  padding-right: 10px;
}

.score-bg {
  height: 20%;
  width: 100%;
  display: block;
  margin-bottom: 1rem;
}

.col-sentiment-card-score {
  max-width: 53%;
  margin-right: 1rem;
}

.col-emotion-card {
  max-width: 48%;
  border-left: 2px solid rgba(212, 212, 212, 0.769);
  margin: -0.5rem 0 1rem -2rem;
}

.score-right-bg {
  height: 50%;
  width: 40%;
  display: block;
  margin-top: 4rem;
  margin-left: 6rem;
}

.img-height {
  height: 90px !important;
}

// React Tooltip override style
.styles-module_tooltip__mnnfp {
  width: 250% !important;
  z-index: 10 !important;
}

.styles-module_info__BWdHW {
  background-color: #fff !important;
  border: 2px dotted #9f9c9c !important;
  color: #6a6d6d !important;
  font-weight: 400 !important;
}

.styles-module_arrow__K0L3T {
  border-top: 2px dotted #9f9c9c !important;
  border-left: 2px dotted #9f9c9c !important;
}

.z-index-2:hover {
  z-index: 2;
}

.cta-view-button {
  font-size: smaller;
  cursor: pointer;
  text-decoration-line: underline;
}

.cta-modal {
  max-width: 700px !important;
}

.cta-modal-header.row {
  margin: 0.5rem 0.1rem;
  border-bottom: 1px solid lightgray;
}

.cta-modal-data.row {
  border-bottom: 1px solid lightgray;
  margin: 0.5rem 0.1rem;
}

.cta-modal-data.row:last-child {
  border-bottom: none;
}

.cta-modal-first {
  max-width: 28%;
}

.cta-modal-second {
  overflow-wrap: break-word;
}


/* Register page */

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  margin: 0.5rem 1rem;
}

.card-register {
  max-width: 80%;
  margin-top: -0.13rem;
  margin-left: 2.3rem;
  background: rgba(0, 0, 0, 0.5) !important;
  border-radius: 25px !important;
}

form.form-register.MuiBox-root {
  font-family: 'Graphik LC Web' !important;
}

.MuiAlert-action {
  white-space: pre-wrap;
}

/* Register page */

/*Login page */

.login-logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
}

.white-hr {
  margin-left: -0.9rem;
  border: 1px solid #FFFFFF;
  width: 347px;
  height: 0px;
}

.MuiInputBase-root.MuiInput-root:before, .MuiInputBase-root.MuiInput-root:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.42) !important;
}

.login-text, .MuiFormLabel-root.MuiInputLabel-root.Mui-focused, .MuiInputBase-root.MuiInput-root {
  color: #FFFFFF !important;
}

.MuiButtonBase-root.MuiButton-root {
  width: 171px !important;
  height: 37px !important;
  background: #8900C5 !important;
  border-radius: 5px !important;
  font-size: small !important;
  font-weight: 600 !important;
}

form.form-login.MuiBox-root {
  font-family: 'Graphik LC Web' !important;
}

.MuiFormHelperText-root.Mui-error, .MuiFormLabel-root.MuiInputLabel-root.Mui-error {
  color: #ff3246 !important;
}
.btn-primary {
  background-color: #a100ff !important;
  color: #fff !important;
  border-color: #a100ff !important;
}

/*Login page */