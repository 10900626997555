/* .custom-title {
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
}
/* .custom-title::after {
    content: "...";
    position: absolute;
    margin-left: 58%;
    padding: 0 5px;
}
.custom-title:hover {
    width: auto;
    white-space: normal;
    font-size: 20px;
    overflow: visible;
    z-index: 1;
}
.custom-title:hover::after {
    content: "";
} */
.userName {
  color: white !important;
}
.custom-title {
    font-size: 30px;
  }
  
  .custom-title-small {
    font-size: 24px;
  }
  .display{
    display: block;
  }
  
  .hide{
    display: none;
  }

  .adminDropdown{
    background: rgb(255, 255, 255);
    color: black;
    position: absolute;
    z-index: 1;
    width: 382px;
    padding-top: 16px;
  }
  .adminDropdown a{
    color: black !important;
    font-family: Graphik;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    

  }

  .adminDropdown a:hover{
    font-family: Graphik;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #A100FF !important;
    ;
  }
    .navMenu {
      font-family: Graphik;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
    }
    .navMenu.active {
      background: none !important;
      font-family: Graphik;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
    }
  .menu {
    height: 8.5vh;
    padding-top: 4px;
    padding-left: 5vw;
    padding-right: 5.5vw;
  }
