.topnav {
  height: 61px;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  justify-content: space-between; /* Align title and nav to the ends */
  align-items: center; /* Vertically center items */
  background-color: #02054E;
  padding: 20px;
}

.headerText {
  color: #FFFFFF;
  font-family: Graphik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  display: flex; /* Add display: flex */
  align-items: center; /* Center vertically */
}

.nav-container {
  display: flex;
  align-items: center;
  position: relative; /* Ensure relative positioning for dropdown */
}

/* Style the dropdown menu to open above the topnav */
.nav-container .dropdown-menu {
  position: fixed !important;
  top: -100%; /* Move the dropdown above the topnav */
  right: 0; /* Align it to the right */
}

.imgContainer {
  top: 0px;
  position: relative;
  color: white;
  /* width: 1441px; */
  height: 18.6vh;
  margin-top: -4px;
  /* background: url("/assets/img/header.png"), lightgray 0.784px 3px / 228.95% 436.913% no-repeat; */
   /* mix-blend-mode: exclusion; */
}
.contentLayout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 700px;
}
.top-left {
  position: absolute;
  top: 7.03vh;
  left: 5vw;
}

.headlineText {
  color: #FFF;
  font-family: Graphik;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px; /* 112.5% */
  letter-spacing: -0.24px;
  /* padding: 4rem; */
}