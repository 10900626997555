/*--------------------------------

nucleo-icons Web Font - built using nucleoapp.com
License - nucleoapp.com/license/

-------------------------------- */
@font-face {
    font-family: 'nucleo-icons';
    src: url('../../fonts/nucleo-icons.eot');
    src: url('../../fonts/nucleo-icons.eot') format('embedded-opentype'), url('../../fonts/nucleo-icons.woff2') format('woff2'), url('../../fonts/nucleo-icons.woff') format('woff'), url('../../fonts/nucleo-icons.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  /*------------------------
      base class definition
  -------------------------*/
  .nc-icon {
    display: inline-block;
    font: normal normal normal 14px/1 'nucleo-icons';
    font-size: inherit;
    speak: none;
    text-transform: none;
    /* Better Font Rendering */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  /*------------------------
    change icon size
  -------------------------*/
  .nc-icon.lg {
    font-size: 1.33333333em;
    vertical-align: -16%;
  }
  .nc-icon.x2 {
    font-size: 2em;
  }
  .nc-icon.x3 {
    font-size: 3em;
  }
  /*----------------------------------
    add a square/circle background
  -----------------------------------*/
  .nc-icon.square,
  .nc-icon.circle {
    padding: 0.33333333em;
    vertical-align: -16%;
    background-color: #eee;
  }
  .nc-icon.circle {
    border-radius: 50%;
  }
  /*------------------------
    list icons
  -------------------------*/
  .nc-icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
  }
  .nc-icon-ul > li {
    position: relative;
  }
  .nc-icon-ul > li > .nc-icon {
    position: absolute;
    left: -1.57142857em;
    top: 0.14285714em;
    text-align: center;
  }
  .nc-icon-ul > li > .nc-icon.lg {
    top: 0;
    left: -1.35714286em;
  }
  .nc-icon-ul > li > .nc-icon.circle,
  .nc-icon-ul > li > .nc-icon.square {
    top: -0.19047619em;
    left: -1.9047619em;
  }
  /*------------------------
    spinning icons
  -------------------------*/
  .nc-icon.spin {
    -webkit-animation: nc-icon-spin 2s infinite linear;
    -moz-animation: nc-icon-spin 2s infinite linear;
    animation: nc-icon-spin 2s infinite linear;
  }
  @-webkit-keyframes nc-icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @-moz-keyframes nc-icon-spin {
    0% {
      -moz-transform: rotate(0deg);
    }
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @keyframes nc-icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  /*------------------------
    rotated/flipped icons
  -------------------------*/
  .nc-icon.rotate-90 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .nc-icon.rotate-180 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .nc-icon.rotate-270 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  .nc-icon.flip-y {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
    -webkit-transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }
  .nc-icon.flip-x {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: scale(1, -1);
    -moz-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    -o-transform: scale(1, -1);
    transform: scale(1, -1);
  }
  /*------------------------
      font icons
  -------------------------*/
  
  .nc-air-baloon::before {
      content: "\ea01";
  }
  
  .nc-album-2::before {
      content: "\ea02";
  }
  
  .nc-alert-circle-i::before {
      content: "\ea04";
  }
  
  .nc-align-center::before {
      content: "\ea03";
  }
  
  .nc-align-left-2::before {
      content: "\ea05";
  }
  
  .nc-ambulance::before {
      content: "\ea06";
  }
  
  .nc-app::before {
      content: "\ea07";
  }
  
  .nc-atom::before {
      content: "\ea08";
  }
  
  .nc-badge::before {
      content: "\ea09";
  }
  
  .nc-bag-16::before {
      content: "\ea0a";
  }
  
  .nc-bank::before {
      content: "\ea0b";
  }
  
  .nc-basket::before {
      content: "\ea0c";
  }
  
  .nc-bell-55::before {
      content: "\ea0d";
  }
  
  .nc-bold::before {
      content: "\ea0e";
  }
  
  .nc-book-bookmark::before {
      content: "\ea0f";
  }
  
  .nc-bookmark-2::before {
      content: "\ea10";
  }
  
  .nc-box-2::before {
      content: "\ea11";
  }
  
  .nc-box::before {
      content: "\ea12";
  }
  
  .nc-briefcase-24::before {
      content: "\ea13";
  }
  
  .nc-bulb-63::before {
      content: "\ea14";
  }
  
  .nc-bullet-list-67::before {
      content: "\ea15";
  }
  
  .nc-bus-front-12::before {
      content: "\ea16";
  }
  
  .nc-button-pause::before {
      content: "\ea17";
  }
  
  .nc-button-play::before {
      content: "\ea18";
  }
  
  .nc-button-power::before {
      content: "\ea19";
  }
  
  .nc-calendar-60::before {
      content: "\ea1a";
  }
  
  .nc-camera-compact::before {
      content: "\ea1b";
  }
  
  .nc-caps-small::before {
      content: "\ea1c";
  }
  
  .nc-cart-simple::before {
      content: "\ea1d";
  }
  
  .nc-chart-bar-32::before {
      content: "\ea1e";
  }
  
  .nc-chart-pie-36::before {
      content: "\ea1f";
  }
  
  .nc-chat-33::before {
      content: "\ea20";
  }
  
  .nc-check-2::before {
      content: "\ea21";
  }
  
  .nc-circle-10::before {
      content: "\ea22";
  }
  
  .nc-cloud-download-93::before {
      content: "\ea23";
  }
  
  .nc-cloud-upload-94::before {
      content: "\ea24";
  }
  
  .nc-compass-05::before {
      content: "\ea25";
  }
  
  .nc-controller-modern::before {
      content: "\ea26";
  }
  
  .nc-credit-card::before {
      content: "\ea27";
  }
  
  .nc-delivery-fast::before {
      content: "\ea28";
  }
  
  .nc-diamond::before {
      content: "\ea29";
  }
  
  .nc-email-85::before {
      content: "\ea2a";
  }
  
  .nc-favourite-28::before {
      content: "\ea2b";
  }
  
  .nc-glasses-2::before {
      content: "\ea2c";
  }
  
  .nc-globe-2::before {
      content: "\ea2d";
  }
  
  .nc-globe::before {
      content: "\ea2e";
  }
  
  .nc-hat-3::before {
      content: "\ea2f";
  }
  
  .nc-headphones::before {
      content: "\ea30";
  }
  
  .nc-html5::before {
      content: "\ea31";
  }
  
  .nc-image::before {
      content: "\ea32";
  }
  
  .nc-istanbul::before {
      content: "\ea33";
  }
  
  .nc-key-25::before {
      content: "\ea34";
  }
  
  .nc-laptop::before {
      content: "\ea35";
  }
  
  .nc-layout-11::before {
      content: "\ea36";
  }
  
  .nc-lock-circle-open::before {
      content: "\ea37";
  }
  
  .nc-map-big::before {
      content: "\ea38";
  }
  
  .nc-minimal-down::before {
      content: "\ea39";
  }
  
  .nc-minimal-left::before {
      content: "\ea3a";
  }
  
  .nc-minimal-right::before {
      content: "\ea3b";
  }
  
  .nc-minimal-up::before {
      content: "\ea3c";
  }
  
  .nc-mobile::before {
      content: "\ea3d";
  }
  
  .nc-money-coins::before {
      content: "\ea3e";
  }
  
  .nc-note-03::before {
      content: "\ea3f";
  }
  
  .nc-palette::before {
      content: "\ea40";
  }
  
  .nc-paper::before {
      content: "\ea41";
  }
  
  .nc-pin-3::before {
      content: "\ea42";
  }
  
  .nc-planet::before {
      content: "\ea43";
  }
  
  .nc-refresh-69::before {
      content: "\ea44";
  }
  
  .nc-ruler-pencil::before {
      content: "\ea45";
  }
  
  .nc-satisfied::before {
      content: "\ea46";
  }
  
  .nc-scissors::before {
      content: "\ea47";
  }
  
  .nc-send::before {
      content: "\ea48";
  }
  
  .nc-settings-gear-65::before {
      content: "\ea49";
  }
  
  .nc-settings::before {
      content: "\ea4a";
  }
  
  .nc-share-66::before {
      content: "\ea4b";
  }
  
  .nc-shop::before {
      content: "\ea4c";
  }
  
  .nc-simple-add::before {
      content: "\ea4d";
  }
  
  .nc-simple-delete::before {
      content: "\ea4e";
  }
  
  .nc-simple-remove::before {
      content: "\ea4f";
  }
  
  .nc-single-02::before {
      content: "\ea50";
  }
  
  .nc-single-copy-04::before {
      content: "\ea51";
  }
  
  .nc-sound-wave::before {
      content: "\ea52";
  }
  
  .nc-spaceship::before {
      content: "\ea53";
  }
  
  .nc-sun-fog-29::before {
      content: "\ea54";
  }
  
  .nc-support-17::before {
      content: "\ea55";
  }
  
  .nc-tablet-2::before {
      content: "\ea56";
  }
  
  .nc-tag-content::before {
      content: "\ea57";
  }
  
  .nc-tap-01::before {
      content: "\ea58";
  }
  
  .nc-tie-bow::before {
      content: "\ea59";
  }
  
  .nc-tile-56::before {
      content: "\ea5a";
  }
  
  .nc-time-alarm::before {
      content: "\ea5b";
  }
  
  .nc-touch-id::before {
      content: "\ea5c";
  }
  
  .nc-trophy::before {
      content: "\ea5d";
  }
  
  .nc-tv-2::before {
      content: "\ea5e";
  }
  
  .nc-umbrella-13::before {
      content: "\ea5f";
  }
  
  .nc-user-run::before {
      content: "\ea60";
  }
  
  .nc-vector::before {
      content: "\ea61";
  }
  
  .nc-watch-time::before {
      content: "\ea62";
  }
  
  .nc-world-2::before {
      content: "\ea63";
  }
  
  .nc-zoom-split::before {
      content: "\ea64";
  }
  
  
  /* all icon font classes list here */
  