img{
    max-width: 100%;
    border-radius: $border-radius-small;
}
.img-raised{
    box-shadow: $box-shadow-raised;
}
.logo-card img {
    max-width: 50%;
}
